<template>
  <div class="container">
    <section class="hero is-small is-primary">
      <div class="hero-body">
        <div class="columns is-vcentered">
          <div class="column is-two-fifths">
            <!-- TODO replace with sad clappy -->
            <b-image
              :src="clappyImage"
              alt="Clappy"
              responsive
            ></b-image>
          </div>
          <div class="column">
            <p class="title">{{ $route.params.id }} Error</p>
            <p class="subtitle">{{ errorMessage }}<br/><small class="is-size-6">Please submit bugs with the <code>/bug</code> discord command</small></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getUser } from '@/api/users'

export default {
  data: () => ({
    errors: {
      404: 'Page not found',
      503: 'Service Unavailable'
    }
  }),
  computed: {
    errorMessage () {
      return this.errors[this.$route.params.id] || 'Clappy did not see that coming!'
    },
    clappyImage () {
      if (this.computedUser && this.computedUser.settings.clappyImageUrl) return this.computedUser.settings.clappyImageUrl
      return 'https://cdn.discordapp.com/attachments/796094456129388544/875886301885395024/file.png'
    },
    computedUser () {
      return (this.$store.state.user.user && this.$store.state.user.user.id) ? this.$store.state.user.user : null
    }
  },
  methods: {
    async fetchUser () {
      this.$store.dispatch('user/setUser', await getUser())
    }
  },
  mounted () {
    if (!this.computedUser) { this.fetchUser() }
  }
}
</script>

<style>

</style>
